<template>
  <div class="form-group">
    <div
      id="login-captcha"
      class="mtcaptcha"
    />
  </div>
</template>

<script>

export default {
  name: 'Captcha',

  created() {
    window.mtcaptchaConfig.error_callback = this.mtCaptchaErrorCallback;
    window.mtcaptchaConfig['verified-callback'] = this.mtCaptchaVerifiedCallback;
  },

  methods: {
    mtCaptchaErrorCallback() {
      this.$emit('captcha-error');
    },

    mtCaptchaVerifiedCallback(resp) {
      this.$emit('captcha-verified', resp.verifiedToken);
    },
  },
};
</script>
