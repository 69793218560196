<template>
  <div
    class="px-4 d-flex justify-center align-center"
    style="height: 100%;"
  >
    <two-factor-tab-item-dialog-apply @success="processToken($event)" />

    <div style="width: 100%;">
      <!-- ЗАГОЛОВОК -->
      <v-row>
        <v-col
          cols="12"
          class="text-center"
        >
          <h1 class="font-weight-thin">
            <img
              src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/271/ghost_1f47b.png"
              style="width: 36px; height: auto; position: relative; top: 5px;"
              class="mr-1"
            >
            Dolphin Anty Admin
          </h1>
        </v-col>
      </v-row>

      <!-- ЛОГИН -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
          offset-sm="4"
        >
          <v-text-field
            v-model="login"
            label="Логин"
            outlined
            dense
            hide-details
            :spellcheck="false"
            @keydown.enter="logIn"
          />
        </v-col>
      </v-row>

      <!-- ПАРОЛЬ -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
          offset-sm="4"
        >
          <v-text-field
            v-model="password"
            label="Пароль"
            outlined
            dense
            hide-details
            :type="showPassword ? '' : 'password'"
            :spellcheck="false"
            class="centralize-append-icon"
            @keydown.enter="logIn"
          >
            <template #append>
              <!-- ПОКАЗАТЬ ПАРОЛЬ -->
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    color="grey"
                    small
                    icon
                    v-on="on"
                    @click="showPassword = !showPassword"
                  >
                    <v-icon
                      v-if="!showPassword"
                      size="16"
                    >
                      mdi-eye
                    </v-icon>
                    <v-icon
                      v-else
                      size="16"
                    >
                      mdi-eye-off
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ !showPassword ? 'Показать пароль' : 'Скрыть пароль' }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <div
        v-if="showCaptcha"
        class="captcha"
      >
        <vue-hcaptcha
          v-if="isShowHCaptcha"
          ref="captcha"
          :sitekey="captchaSiteKey"
          @verify="verifyMethod"
          @error="errorMethod"
        />
        <captcha
          v-if="isShowMtCaptcha"
          @captcha-error="handleCaptchaError"
          @captcha-verified="handleCaptchaVerified"
        />
      </div>

      <!-- КНОПКИ -->
      <v-row>
        <v-col
          cols="12"
          style="text-align: center"
        >
          <div class="mb-4">
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="login === '' || password === '' || (formDisabled && showCaptcha)"
              @click="logIn"
            >
              Войти
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TwoFactorTabItemDialogApply from '@/components/settings/twoFactor/TwoFactorTabItemDialogApply.vue';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Captcha from '@/components/Captcha.vue';
import captchaKeys from '@/constants/authorization/captchaKeys';
import getMathHeaders from '@/helpers/common/axiosHeaders';

export default {
  name: 'Login',

  components: {
    Captcha,
    TwoFactorTabItemDialogApply,
    VueHcaptcha,
  },

  data() {
    return {
      login: '',
      password: '',
      loading: false,
      showPassword: false,
      formDisabled: true,
      captcha: null,
      showCaptcha: false,
      captchaSiteKey: captchaKeys.H_CAPTCHA_KEY_PROD, // ToDo change to your captcha key
      isShowMtCaptcha: false,
      isShowHCaptcha: false,
    };
  },

  computed: {
    ...mapGetters({
      profile: 'main/profile',
    }),
  },

  methods: {
    verifyMethod(response) {
      this.captcha = response;
      this.formDisabled = false;
    },

    errorMethod(response) {
      this.captcha = response;
    },

    handleCaptchaError() {
      this.formDisabled = true;
    },

    handleCaptchaVerified(verifiedToken) {
      this.captcha = verifiedToken;
      this.formDisabled = false;
    },

    async logIn() {
      if (this.login === '' || this.password === '') return;

      this.loading = true;

      try {
        const mathHeaders = await getMathHeaders();
        const response = await this.api.post(
          '/auth/login',
          {
            username: this.login,
            password: this.password,
            captcha: this.captcha,
          },
          {
            headers: { ...this.api.headers, ...mathHeaders },
          },
        );

        if (!response.status) {
          throw response.response.status;
        } else if (response.status && response.status !== 200) {
          throw response.status;
        }

        if (response.data.twoFactory) {
          await this.$store.dispatch('settings/setTwoFactor', {
            applyType: 'login',
            username: this.login,
            password: this.password,
          });
          this.$store.dispatch('settings/openDialog', 'apply');
        } else {
          await this.processToken(response.data);
        }
      } catch (error) {
        const captchaRef = this.$refs?.captcha;
        this.formDisabled = true;

        if (this.showCaptcha) {
          if (this.isShowMtCaptcha) {
            window.mtcaptcha.resetUI();
            window.mtcaptchaConfig.renderQueue.push('login-captcha');
          }

          if (this.isShowHCaptcha) {
            captchaRef.reset();
          }
        }

        if (error === 401 || error.response?.status === 401) {
          this.$store.dispatch('main/alert', {
            color: 'error',
            message: 'Неверные данные',
          });
        }

        if (error === 429 || error.response?.status === 429) {
          this.$store.dispatch('main/alert', {
            color: 'error',
            message: 'Слишком много попыток авторизации. Подождите 1 минуту.',
          });
        }

        if (error === 400 || error.response?.status === 400) {
          this.$store.dispatch('main/alert', {
            color: 'error',
            message: 'Требуется капча',
          });
          this.showCaptcha = true;

          switch (error.data) {
            case 'hcaptcha':
              captchaRef.reset();
              this.isShowMtCaptcha = false;
              this.isShowHCaptcha = true;
              break;
            default:
              window.mtcaptcha.resetUI();
              window.mtcaptchaConfig.renderQueue.push('login-captcha');
              this.isShowHCaptcha = false;
              this.isShowMtCaptcha = true;
              break;
          }
        }
      }

      this.loading = false;
    },

    async processToken(tokens) {
      this.api.defaults.headers.Authorization = `Bearer ${tokens.token}`;
      localStorage.setItem('apiToken', tokens.token);
      localStorage.setItem('refreshToken', tokens.refresh_token);

      await this.$router.push({ path: '/' });
      window.location.reload();
    },
  },
};
</script>
<style>
.captcha {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px
}
</style>
